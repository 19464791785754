import { useCallback } from 'react'
import { useBookingContext } from '~/pages/AppointmentIntake/contexts/NewBookingContext'
import { usePublicCreatePatientAndAppointmentV1Mutation } from '~/graphql/java/mutations'
import { useGetPchSessionId } from '~/hooks/useGetPchSessionId'

export const useSubmitWaitlistIntake = () => {
  const { patientIntakeRecords, numberOfPersons, pharmacy, method } =
    useBookingContext()
  const waitlistIntake = patientIntakeRecords?.[0]

  const pchSessionId = useGetPchSessionId()

  if (!waitlistIntake) {
    throw new Error('Waitlist intake is not set')
  }

  if (pharmacy?.appointmentTypes?.length !== 1) {
    throw new Error('Waitlist is supported for single service only')
  }

  const appointmentType = pharmacy.appointmentTypes[0]

  const [publicCreatePatientAndAppointmentMutation, { loading, error }] =
    usePublicCreatePatientAndAppointmentV1Mutation({
      context: {
        headers: {
          'x-pharmacyid': pharmacy.id,
        },
      },
      variables: {
        publicCreatePatientAndIntakeAnswerInput: [
          {
            patient: {
              firstName: waitlistIntake.patient.firstName,
              lastName: waitlistIntake.patient.lastName,
              email: waitlistIntake.patient.email,
              birthDate: waitlistIntake.patient.birthDate,
              patientConsent: waitlistIntake.patient.patientConsent,
              phone: {
                cell: waitlistIntake.patient.phone.cell,
              },
              patientIntakeAnswers: [],
            },
          },
        ],
        publicCreateAppointmentInput: {
          method,
          appointmentTypeId: Number(appointmentType.id),
          noOfPeople: numberOfPersons,
        },
        isWalkIn: false,
        isWaitlisted: true,
        pchSessionId,
      },
    })

  const submit = useCallback(async () => {
    const response = await publicCreatePatientAndAppointmentMutation()
    return response?.data?.publicCreatePatientAndAppointmentV1
  }, [publicCreatePatientAndAppointmentMutation])

  return {
    submit,
    loading,
    error,
  }
}
