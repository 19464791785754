import { Pharmacy } from '~/lib/pharmacy'
import {
  GetAllAaisForPharmacyQuery,
  useGetAllAaisForPharmacyQuery,
} from '~/graphql/types/schemaNode.type'
import { EServer } from '~/graphql/hooks/useGraphQL/links'
import { getTenantId } from '~/lib/tenantMetadata'
import { NetworkStatus } from '@apollo/client'

export type PharmacyAai =
  GetAllAaisForPharmacyQuery['findAllAppointmentActivityInstancesWithAppointmentTypes'][number]

export const useGetAllAaisForPharmacy = (
  pharmacy?: Pick<Pharmacy, 'id' | 'province'>,
  language?: string,
  skip?: boolean
) => {
  const { data, loading, error, refetch, networkStatus } =
    useGetAllAaisForPharmacyQuery({
      variables: {
        locationId: pharmacy?.id,
        organizationId: undefined, // TODO: need to confirm what value this should be
        regionCode: pharmacy?.province,
        customerId: getTenantId(),
        countryCode: 'CA',
      },
      context: {
        clientName: EServer.NODE,
        headers: {
          'accept-language': language || 'en-CA',
        },
      },
      notifyOnNetworkStatusChange: true,
      skip: skip || !pharmacy?.id || !pharmacy?.province,
    })

  if (!error) {
    /**
     *  TODO: Need to soft fail because request fails sometimes on first load, guessing graphql state on FE isn't ready yet
     *  removing console.error since its poluting the console but doesn't cause any harm
     */
    // console.error('useGetAllAaisForPharmacy: soft failing')
  }

  const aais: PharmacyAai[] =
    data?.findAllAppointmentActivityInstancesWithAppointmentTypes ?? []

  return {
    aais,
    refetchPharmacyAais: refetch,
    loading,
    refetching: networkStatus === NetworkStatus.refetch,
  }
}
