import * as React from 'react'
import MuiToolTip, { TooltipProps } from '@material-ui/core/Tooltip'

import { ToolTipText } from './ToolTip.styles'

export const ToolTip: React.FC<
  Partial<TooltipProps> & {
    children: React.ReactElement
    text?: string
    disable?: boolean
  }
> = (props) => {
  if (props.disable) {
    return props.children
  }
  return (
    <MuiToolTip
      title={<ToolTipText>{props.text}</ToolTipText>}
      arrow
      placement="top"
      enterTouchDelay={0}
      leaveTouchDelay={5000}
      {...props}
    />
  )
}
