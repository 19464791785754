import React, { useEffect } from 'react'
import { LayoutWrapper } from '~/componentsTs/PatientSideLayout'

import { usePharmacyContext, useWhiteLabelContext } from '../../contexts'
import { Body, PageContainer } from './AppointmentManagement.styles'
import {
  Appointment,
  useGetBookingForAppointmentManagementQuery,
  useGetBookingForAppointmentManagementWithNadQuery,
} from '~/graphql/types/schemaNode.type'
import { useParams } from 'react-router-dom'
import { EServer } from '~/graphql/hooks/useGraphQL/links'
import { Loading } from '~/components/loading/loading'
import { useMedMeTranslation } from '~/hooks/useMedMeTranslation'
import { useBookingManagementContext } from '../../contexts/BookingManagementContext'
import CancelComplete from '~/pages/cancelAppointment/cancelComplete/cancelComplete'
import { getPharmacyId } from '~/lib/tenantMetadata'
import { FeatureFlag, useFeatureFlag } from '~/tools/featureGating'
import { useFindSublocationById } from '~/hooks/useFindSublocationById'
import { getClaimCodeFromRescheduleAppointmentData } from '~/util/claimCode'

interface AppointmentManagementProps {
  children: React.ReactNode | React.ReactNode[]
}

export function AppointmentManagement({
  children,
}: AppointmentManagementProps) {
  const { pharmacy: initialPharmacy } = usePharmacyContext()
  const { whiteLabel } = useWhiteLabelContext()
  const { t } = useMedMeTranslation()
  const { key1, key2, key3 } = useParams<Record<string, string>>()

  const isSkipToEarliestTimeslotEnabled = useFeatureFlag(
    FeatureFlag.ENABLE_SKIP_TO_EARLIEST_WEEK_IN_TIMESLOT_SELECTION,
    false
  )

  const queryOptions = {
    variables: {
      key1,
      key2,
      key3,
    },
    context: {
      clientName: EServer.NODE,
      headers: {
        'x-pharmacyid': getPharmacyId(true),
      },
    },
    fetchPolicy: 'network-only' as const,
  }

  const { data: dataWithNad, error: errorWithNad } =
    useGetBookingForAppointmentManagementWithNadQuery({
      ...queryOptions,
      skip: !key1 || !key2 || !key3 || !isSkipToEarliestTimeslotEnabled,
    })

  const { data: dataWithoutNad, error: errorWithoutNad } =
    useGetBookingForAppointmentManagementQuery({
      ...queryOptions,
      skip: !key1 || !key2 || !key3 || isSkipToEarliestTimeslotEnabled,
    })

  const data = dataWithNad || dataWithoutNad
  const error = errorWithNad || errorWithoutNad

  const {
    isCancelled,
    isRescheduled,
    isInitialized,
    pharmacy,
    existingBooking,
    dispatch,
    mainPatient,
  } = useBookingManagementContext()

  useEffect(() => {
    if (!mainPatient) return

    const claimCode = getClaimCodeFromRescheduleAppointmentData(mainPatient)

    dispatch({
      type: 'setClaimCode',
      payload: claimCode,
    })
  }, [mainPatient, dispatch])

  useEffect(() => {
    if (!data?.getBookingByKeys) {
      return
    }

    const { patients } = data.getBookingByKeys
    const appointments = patients?.flatMap((patient) => patient.appointments)

    if (appointments.length === 0) {
      throw new Error('No appointments found')
    }

    const mainPatient = patients?.find((patient) =>
      patient.appointments?.every((appointment) => appointment.isMainPatient)
    )

    dispatch({
      type: 'setMainPatient',
      payload: mainPatient,
    })

    dispatch({
      type: 'setAppointments',
      payload: appointments as Appointment[],
    })

    if ('nextAvailableDate' in data.getBookingByKeys) {
      dispatch({
        type: 'setNextAvailableDate',
        payload: data.getBookingByKeys.nextAvailableDate,
      })
    }
  }, [data, dispatch])

  const { sublocationData, isSublocationLoading } = useFindSublocationById()

  useEffect(() => {
    if (sublocationData) {
      dispatch({
        type: 'setExternalClinic',
        payload: sublocationData,
      })
    }
  }, [sublocationData, dispatch])

  const loading = isSublocationLoading || !isInitialized

  if (error) {
    throw error
  }

  return loading ? (
    <Loading title={t('loading.title.loading')} />
  ) : (
    <LayoutWrapper
      whiteLabel={whiteLabel}
      pharmacy={initialPharmacy}
      isLastForm={isCancelled || isRescheduled}
    >
      <Body>
        <PageContainer>
          {isCancelled ? (
            <CancelComplete
              pharmacy={pharmacy}
              existingBooking={existingBooking}
            />
          ) : (
            children
          )}
        </PageContainer>
      </Body>
    </LayoutWrapper>
  )
}
