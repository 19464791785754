import { useApolloClient } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import {
  getCurrentCognitoUser,
  getSessionAsync,
  globalSignOutAsync,
} from '~/auth/awsCognitoMethods'
import { hasSamlLogoutAccess } from '~/util/featureAccess'
import {
  getAuthLogoutUrl,
  getIdToken,
  getIsSamlLogin,
  getRefreshToken,
  revokeRefreshTokenForEmailPasswordLogin,
  updateClientQueryParams,
} from '~/auth'
import { selectiveClearLocalStorage } from './util'

const cognitoLogout = async () => {
  const cognitoUser = getCurrentCognitoUser()
  if (cognitoUser) {
    await getSessionAsync(cognitoUser)
    await globalSignOutAsync(cognitoUser)
  }
}

let isLoggingOut = false

// WARNING, Only use this hook within components within Router wrappers as this uses history
const useLogoutHook = () => {
  const client = useApolloClient()
  const history = useHistory()
  const dispatch = useDispatch()

  const logout = async (options = {}) => {
    if (isLoggingOut) return

    isLoggingOut = true

    const updatedOptions = {
      endPath: '/',
      resetCache: true,
      resetRedux: true,
      clearLocalStorage: true,
      clearCookie: true,
      logoutGql: true,
      logoutCognito: true,
      ...options,
    }
    const {
      endPath,
      resetCache,
      resetRedux,
      clearLocalStorage,
      clearCookie,
      logoutGql,
      logoutCognito,
    } = updatedOptions

    const idToken = getIdToken()
    if (
      idToken &&
      logoutCognito &&
      hasSamlLogoutAccess() &&
      getIsSamlLogin(idToken)
    ) {
      await cognitoLogout().catch((err) =>
        console.error('Failed to logout of cognito\n', err)
      )
    }
    const refreshToken = getRefreshToken()

    if (refreshToken && logoutCognito) {
      await revokeRefreshTokenForEmailPasswordLogin(refreshToken).catch((err) =>
        console.error('Failed to revoke refresh token\n', err)
      )
    }

    if (clearLocalStorage) selectiveClearLocalStorage()

    if (clearCookie) {
      document.cookie =
        'path=/; domain=.microsoft.com; expires=' + new Date(0).toUTCString()
    }

    if (resetRedux) dispatch({ type: 'LOGOUT' })

    if (logoutCognito && hasSamlLogoutAccess() && getIsSamlLogin(idToken)) {
      window.location.href = getAuthLogoutUrl()
    } else {
      if (resetCache) await client.cache.reset()
      if (logoutGql) {
        client.writeQuery(updateClientQueryParams(false, false, false, false))
      }
      if (endPath) history.push(endPath)
    }
    isLoggingOut = false
  }

  return {
    logout,
  }
}

export default useLogoutHook
