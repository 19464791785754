export const PROVINCE_DROPDOWN_OPTIONS = [
  {
    id: 'j6z3klg',
    text: 'Select Province',
    value: '',
  },
  {
    id: 'mb43jw8',
    text: 'Alberta',
    value: 'AB',
  },
  {
    id: 'ruez0ts',
    text: 'British Columbia',
    value: 'BC',
  },
  {
    id: '2hwzov6',
    text: 'Manitoba',
    value: 'MB',
  },
  {
    id: 'e7hw0bz',
    text: 'New Brunswick',
    value: 'NB',
  },
  {
    id: 'loi39p2',
    text: 'Newfoundland and Labrador',
    value: 'NL',
  },
  {
    id: 'zd6719m',
    text: 'Nova Scotia',
    value: 'NS',
  },
  {
    id: '97lhxi3',
    text: 'Ontario',
    value: 'ON',
  },
  {
    id: '43m73u0',
    text: 'Prince Edward Island',
    value: 'PE',
  },
  {
    id: 'fy2ndil',
    text: 'Quebec',
    value: 'QC',
  },
  {
    id: 'pmdf896',
    text: 'Saskatchewan',
    value: 'SK',
  },
  {
    id: '97q4zyd',
    text: 'Northwest Territories',
    value: 'NT',
  },
  {
    id: 'sxo1vqh',
    text: 'Nunavut',
    value: 'NU',
  },
  {
    id: 'jcwga9p',
    text: 'Yukon',
    value: 'YT',
  },
  {
    id: 's9so1vq',
    text: 'Other',
    value: 'other',
  },
]

export const PROFESSIONAL_DESIGNATION_OPTIONS = [
  {
    id: 'xarr1en',
    value: '',
    text: 'Select Designation',
  },
  {
    id: 'km6b025',
    value: 'Doctor (MD)',
    text: 'Doctor (MD)',
  },
  {
    id: 'xlyhxzb',
    value: 'NP',
    text: 'NP',
  },
  {
    id: 's2diu8t',
    value: 'OT',
    text: 'OT',
  },
  {
    id: 'erez8i8',
    value: 'OD',
    text: 'OD',
  },
  {
    id: 'mjfob5s',
    value: 'Pharmacist (RPh)',
    text: 'Pharmacist (RPh)',
  },
  {
    id: 'hnb7e7b',
    value: 'PT',
    text: 'PT',
  },
  {
    id: 'adl97ck',
    value: 'Psy',
    text: 'Psy',
  },
  {
    id: '6zhv81d',
    value: 'RD',
    text: 'RD',
  },
  {
    id: 'ax7pewr',
    value: 'RDN',
    text: 'RDN',
  },
  {
    id: 'ler70hc',
    value: 'Registered Nurse (RN)',
    text: 'Registered Nurse (RN)',
  },
  {
    id: '54hyock',
    value: 'RT',
    text: 'RT',
  },
  {
    id: '39ffzeb',
    value: 'SW',
    text: 'SW',
  },
  {
    id: 'c7fom4x',
    value: 'Other',
    text: 'Other',
  },
]
