import {
  isMetroPharmacyProdSubdomain,
  isMintDrugsProdSubdomain,
  isRexallProdSubdomain,
  isSdmEnterprise,
  isSdmEnterpriseDevSubdomain,
  isSdmEnterpriseProdSubdomain,
  isSofProdSubdomain,
} from '~/util/enterpriseMethods'

export const initializeGTM = () => {
  const dataLayerIdOld = () => 'GTM-KRPKZZ6'
  const dataLayerIdForSdmLclProd = () => 'G-S32JRDG1D6'
  const dataLayerIdForSdmLclDev = () => 'G-MGTH2E0LYQ'

  const dataLayerIdForRexallProd = () => 'G-RTNH6NSDX3'
  const dataLayerIdForSofProd = () => 'G-32JKJJS4QE'
  const dataLayerIdForMetroProd = () => 'G-3MF5EPEYFH'
  const dataLayerIdForMintDrugsProd = () => 'G-Y6EB9K0B7D'

  const when = (
    condition: () => boolean,
    fn: (id: string) => void,
    getDataLayerId: () => string
  ) => {
    if (condition()) fn(getDataLayerId())
  }

  const isPublicAppointmentBookingURL = (url: string) =>
    /^(\/lcl)?(\/[(0-9)]+)?(\/public|\/schedule).*/.test(url)

  const initialize = (dataLayerId: string) => {
    if (gtag && typeof gtag === 'function') {
      gtag('config', dataLayerId)
    }
  }

  when(
    () =>
      isSdmEnterprise() &&
      /^(\/lcl)?(\/[(0-9)]+)?(\/patient|\/schedule).*/.test(location.pathname),
    initialize,
    dataLayerIdOld
  )

  // analytics for /schedule and /public traffic
  when(
    () =>
      isSdmEnterpriseProdSubdomain(window.location.hostname) &&
      isPublicAppointmentBookingURL(location.pathname),
    initialize,
    dataLayerIdForSdmLclProd
  )

  when(
    () =>
      isSdmEnterpriseDevSubdomain(window.location.hostname) &&
      isPublicAppointmentBookingURL(location.pathname),
    initialize,
    dataLayerIdForSdmLclDev
  )

  when(
    () =>
      isRexallProdSubdomain(window.location.hostname) &&
      isPublicAppointmentBookingURL(location.pathname),
    initialize,
    dataLayerIdForRexallProd
  )

  when(
    () =>
      isSofProdSubdomain(window.location.hostname) &&
      isPublicAppointmentBookingURL(location.pathname),
    initialize,
    dataLayerIdForSofProd
  )

  when(
    () =>
      isMetroPharmacyProdSubdomain(window.location.hostname) &&
      isPublicAppointmentBookingURL(location.pathname),
    initialize,
    dataLayerIdForMetroProd
  )

  when(
    () =>
      isMintDrugsProdSubdomain(window.location.hostname) &&
      isPublicAppointmentBookingURL(location.pathname),
    initialize,
    dataLayerIdForMintDrugsProd
  )
}
