import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { refreshToken, logout } from './auth/util'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { enableMapSet } from 'immer'
import { StylesProvider } from '@material-ui/core/styles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import MomentUtils from '@date-io/moment'
import GlobalStyle from './globalStyles'
import App from './app'
import './i18n'
import { WebViewProvider } from './context/WebViewContext'
import { useGraphQL, getCache } from '~/graphql/hooks/useGraphQL'
import { initializeGTM } from '~/googleTagManager/googleTagManager'
import { store, persistor } from './store'
import { initializeFeatureFlagCLI } from './hooks/useFeatureFlags/featureFlagCli'
import Loading from './components/loading'
import { Box } from '@material-ui/core'
import { isLocalHost } from './util/envMethods'
import { isLCLRedirect } from './util/enterpriseMethods'
import { initializeDayJs } from './util/date'
import { initMonitoring, reportException } from './tools/monitoring'
import { Debugger } from './componentsTs/debugger'
import { withFeatureGatingProvider } from './tools/featureGating'
import { ErrorBoundary, ErrorComponent } from '~/componentsTs/ErrorBoundary'
import {
  getMetadata,
  checkMetadataAndReport,
  TenantMetadataError,
  initTenantMetadata,
} from './lib/tenantMetadata'
import { SnackbarProvider } from './componentsTs/Snackbar'

// important to note: react-router-dom version:5.2.0 only works with history version:4.10.1
const history = createBrowserHistory({
  basename: isLCLRedirect() ? '/lcl' : undefined,
})

const root = document.getElementById('root')

// TODO: Metadata will be used for application initialization
// https://medmehealth.atlassian.net/browse/NEB-1062, https://medmehealth.atlassian.net/browse/NEB-836, https://medmehealth.atlassian.net/browse/NEB-837
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function startApp({ metadata } = {}) {
  if (metadata) {
    initTenantMetadata(metadata)
  }

  enableMapSet()
  initializeGTM()
  initializeFeatureFlagCLI()
  initializeDayJs()
  initMonitoring({ history })

  const Client = () => {
    const cache = getCache()
    const { GraphQLProvider } = useGraphQL({
      refreshToken: () => refreshToken(cache, store),
      logout: () => logout(cache, store),
      cache,
    })

    return (
      <WebViewProvider history={history}>
        <GraphQLProvider>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <Router history={history}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <StylesProvider injectFirst>
                    <Suspense
                      fallback={
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          minHeight="100vh"
                        >
                          <Loading title="Loading ..." />
                        </Box>
                      }
                    >
                      <GlobalStyle />
                      <ErrorBoundary
                        render={() => (
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            minHeight="100vh"
                          >
                            <ErrorComponent />
                          </Box>
                        )}
                      >
                        <SnackbarProvider>
                          <App />
                        </SnackbarProvider>

                        {localStorage.getItem('medme-debugger') === 'true' && (
                          <Debugger />
                        )}
                      </ErrorBoundary>
                    </Suspense>
                  </StylesProvider>
                </MuiPickersUtilsProvider>
              </Router>
            </PersistGate>
          </Provider>
        </GraphQLProvider>
      </WebViewProvider>
    )
  }

  const root = document.getElementById('root')
  const ClientWithFeatureGatingProvider = withFeatureGatingProvider(Client)

  ReactDOM.render(<ClientWithFeatureGatingProvider />, root)

  if (isLocalHost && import.meta.webpackHot) {
    import.meta.webpackHot.accept('./app.js', () => {
      console.log('Hot reload...')
      ReactDOM.render(<ClientWithFeatureGatingProvider />, root)
    })
  }
}

function renderError() {
  ReactDOM.render(
    <StylesProvider injectFirst>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
      >
        <ErrorComponent />
      </Box>
    </StylesProvider>,
    root
  )
}

function renderLoader() {
  ReactDOM.render(
    <StylesProvider injectFirst>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
      >
        <Loading title="Loading ..." />
      </Box>
    </StylesProvider>,
    root
  )
}

function subscribeToLocalStorageChanges(key, callback) {
  const handleStorageChange = (event) => {
    if (event.storageArea === localStorage && event.key === key) {
      callback()
    }
  }
  window.addEventListener('storage', handleStorageChange)

  return () => {
    window.removeEventListener('storage', handleStorageChange)
  }
}

function reportFatalError(error) {
  initMonitoring({ history })
  reportException(error, {
    transactionName: 'App initialization',
    tags: {
      level: 'fatal',
    },
  })
}

function startAppWithMetada(metadata) {
  try {
    if (!metadata) {
      throw new TenantMetadataError('No metadata found')
    }

    if (metadata.error) {
      throw new TenantMetadataError(metadata.error)
    }

    startApp({ metadata })
  } catch (error) {
    renderError()
    reportFatalError(error)
  }
}

function initializeApp() {
  const isAppMetadataFetchEnabled =
    process.env.MEDME_APP_METADATA_FEATURE_ENABLED === 'true'
  const isAppMetadataUsageEnabled =
    process.env.MEDME_APP_METADATA_USAGE_ENABLED === 'true'

  const medmeAppLocalStorageKey =
    process.env.MEDME_APP_METADATA_LOCAL_STORAGE_KEY

  // Both feature flags are disabled
  if (!isAppMetadataFetchEnabled) {
    startApp()
    return
  }

  // Metadata fetch is enabled but not yet used to initialize the app
  if (!isAppMetadataUsageEnabled) {
    startApp()

    const metadata = getMetadata()

    if (metadata) {
      checkMetadataAndReport(metadata)
      return
    }

    const unsubscribe = subscribeToLocalStorageChanges(
      medmeAppLocalStorageKey,
      () => {
        unsubscribe()
        checkMetadataAndReport(getMetadata())
      }
    )
    return
  }

  // Both feature flags are enabled
  const metadata = getMetadata()
  if (metadata) {
    startAppWithMetada(metadata)
    return
  }

  renderLoader()
  const unsubscribe = subscribeToLocalStorageChanges(
    medmeAppLocalStorageKey,
    () => {
      unsubscribe()
      startAppWithMetada(getMetadata())
    }
  )
}

initializeApp()
