import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { Pharmacy } from '~/lib/pharmacy'
import {
  PharmacyAai,
  useGetAllAaisForPharmacy,
} from './useGetAllAaisForPharmacy'
import { getAAITitle } from '~/lib/appointmentActivity'
import Loading from '~/components/loading'
import { useMedMeTranslation } from '~/hooks/useMedMeTranslation'
import { Box } from '@material-ui/core'

/**
 * Context used to store lazy loaded medical data to be shared across components
 */
export const PharmacyAaiContext = React.createContext<PharmacyAaiContextValues>(
  {} as PharmacyAaiContextValues
)

export interface PharmacyAaiContextValues {
  aais: PharmacyAai[]
  apptTypeIdToAaiMap: Record<string, PharmacyAai>
  getAAITitleByAppointmentTypeId(
    appointmentTypeId: string | number
  ): string | null | undefined
  refetchPharmacyAais(): void
}

type Props = {
  pharmacy: Pharmacy
  language?: string
  skip?: boolean
  children: React.ReactNode
}
export const PharmacyAaiContextProvider = ({
  pharmacy,
  language,
  skip,
  children,
}: Props) => {
  const prevLanguageRef = useRef(language)
  const { t } = useMedMeTranslation('pharmacy')
  const { aais, refetchPharmacyAais, loading, refetching } =
    useGetAllAaisForPharmacy(pharmacy, language, skip)
  const apptTypeIdToAaiMap = useMemo(
    () =>
      aais.reduce<Record<string, PharmacyAai>>((acc, aai) => {
        if (aai.appointmentTypeId) {
          acc[aai.appointmentTypeId] = aai
        }
        return acc
      }, {}),
    [aais]
  )

  const getAAITitleByAppointmentTypeId: PharmacyAaiContextValues['getAAITitleByAppointmentTypeId'] =
    useCallback(
      (appointmentTypeId) =>
        getAAITitle(apptTypeIdToAaiMap[String(appointmentTypeId)]),
      [apptTypeIdToAaiMap]
    )

  useEffect(() => {
    if (prevLanguageRef.current !== language) {
      refetchPharmacyAais()
      prevLanguageRef.current = language
    }
  }, [language, prevLanguageRef, refetchPharmacyAais])

  if (loading && !refetching) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
      >
        <Loading title={t('loading.title.loading')} />
      </Box>
    )
  }

  return (
    <PharmacyAaiContext.Provider
      value={{
        aais,
        apptTypeIdToAaiMap,
        getAAITitleByAppointmentTypeId,
        refetchPharmacyAais,
      }}
    >
      {children}
    </PharmacyAaiContext.Provider>
  )
}
