export enum FeatureFlag {
  HIDE_CO_BOOKING_MODAL = 'hide-co-booking-modal', // https://medmehealth.atlassian.net/browse/FEAT-430
  ALLOW_PHARMACIST_SET_RESCHEDULE_AND_CANCELLATION_REASON = 'allow-pharmacist-set-reschedule-and-cancellation-reason', // https://medmehealth.atlassian.net/browse/NEB-749
  ENABLE_PROMO_IMAGE = 'enable-promo-image', // https://medmehealth.atlassian.net/browse/NEB-920
  HIDE_PHARMACIST_WAITLIST = 'hide-pharmacist-waitlist', // https://medmehealth.atlassian.net/browse/NEB-767
  HIDE_PHARMACIST_REPORTS = 'hide-pharmacist-reports', // https://medmehealth.atlassian.net/browse/NEB-767
  HIDE_APPOINTMENT_TYPE_TILE_LOGO = 'hide-appointment-type-tile-logo', // https://medmehealth.atlassian.net/browse/NEB-941
  CONFIGURE_USER_INACTIVITY_LOGOUT_TIME = 'configure-user-inactivity-logout-time', // https://medmehealth.atlassian.net/browse/NEB-998
  ENABLE_MAPFLOW = 'enable-mapflow', // https://medmehealth.atlassian.net/browse/GRAV-666
  MANDATORY_PHONE_FOR_NEW_PATIENT_CREATION = 'enable-mandatory-phone-number-for-new-patient-creation', // https://medmehealth.atlassian.net/browse/NEB-982
  ENABLE_WALK_IN_INDICATOR = 'enable-walk-in-indicator', // https://medmehealth.atlassian.net/browse/NEB-974
  ENABLE_CLINICAL_SEARCH = 'enable-clinical-search', // https://medmehealth.atlassian.net/browse/GRAV-746

  ENABLE_PCH_CONTACT_FORM_PREFILL = 'enable-pch-contact-form-pre-fill', // https://medmehealth.atlassian.net/browse/GRAV-676

  ENABLE_PHARMACIST_CREATE_APPOINTMENT_PATIENT_LANGUAGE = 'enable-pharmacist-create-appointment-patient-language', // https://medmehealth.atlassian.net/browse/NEB-990
  APPT_PATIENT_LANG_PREFERENCE_DEFAULT_LANG_CODE = 'appt-patient-lang-preference-default-lang-code', // https://medmehealth.atlassian.net/browse/NEB-1253
  ENABLE_PATIENT_LOCATION_INPUT_STORE_SELECTION_PAGE = 'enable-patient-location-input-store-selection-page', // https://medmehealth.atlassian.net/browse/NEB-1003
  ENABLE_FBA_ON_STORE_SELECTION_PAGE = 'enable-availability-filters-on-store-selection-page', // https://medmehealth.atlassian.net/browse/NEB-1033
  ENABLE_COBOOKING_FBA_ON_STORE_SELECTION_PAGE = 'enable-cobooking-availability-filters-on-store-selection-page', // https://medmehealth.atlassian.net/browse/NEB-1033
  HIDE_PHARMACIST_AVAILABILITY_END_DATE = 'hide-pharmacist-availability-end-date', // https://medmehealth.atlassian.net/browse/NEB-1077
  ENABLE_LANGUAGE_SELECTOR_ON_PHARMACIST_STORE_SELECTION_PAGE = 'enable-language-selector-on-pharmacist-store-selection-page', // https://medmehealth.atlassian.net/browse/NEB-899
  ENABLE_PHARMACIST_WALKIN_AVAILABILITY = 'enable-pharmacist-walkin-availability', // https://medmehealth.atlassian.net/browse/NEB-1187
  ENABLE_MSB_INDIVIDUAL_RESCHEDULE_CANCEL_PATIENT = 'enable-msb-individual-reschedule-cancel-patient', // https://medmehealth.atlassian.net/browse/NEB-1093
  ENABLE_MSB_INDIVIDUAL_RESCHEDULE_CANCEL_PHARMACIST = 'enable-msb-individual-reschedule-cancel-pharmacist', // https://medmehealth.atlassian.net/browse/NEB-1095
  ENABLE_SKIP_TO_EARLIEST_WEEK_IN_TIMESLOT_SELECTION = 'enable-skip-to-earliest-week-in-timeslot-selection', // https://medmehealth.atlassian.net/browse/NEB-1112
  ENABLE_EXTERNAL_CLINICS_ON_PHARMACY_SETTINGS_PAGE = 'enable-external-clinics-on-pharmacy-settings-page', // https://medmehealth.atlassian.net/browse/NEB-1303
  HIDE_PHARMACIST_SEND_CUSTOM_EMAIL = 'hide-pharmacist-send-custom-email', // https://medmehealth.atlassian.net/browse/NEB-658
  HIDE_APPT_TYPE_PUBLIC_INTAKE_TOGGLE = 'hide-appt-type-public-intake-toggle', // https://medmehealth.atlassian.net/browse/NEB-1448

  ENABLE_APPT_TYPE_DETAILS_CONFIGURATION = 'enable-appt-type-details-configuration', // https://medmehealth.atlassian.net/browse/NEB-1249
  ENABLE_EXTERNAL_CLINICS_APPT_TYPE_DETAILS_CONFIGURATION = 'enable-external-clinics-appt-type-details-configuration', // https://medmehealth.atlassian.net/browse/NEB-1400
  ENABLE_NODE_MERGE_PATIENTS_ENDPOINT = 'enable-node-merge-patients-endpoint', // https://medmehealth.atlassian.net/browse/GRAV-947
  ENABLE_FETCH_IDP_INTEGRATIONS = 'enable-fetch-idp-integrations', // https://medmehealth.atlassian.net/browse/NEB-1567
  ENABLE_SERIES_A_DEMO_MODE = 'enable-series-a-demo-mode', // https://medmehealth.atlassian.net/browse/NEB-1506
  ENABLE_PHARMACIST_STORE_SETTINGS_JSON = 'enable-pharmacist-store-settings-json',
}
