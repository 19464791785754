import styled from 'styled-components'

export const ToolTipText = styled.div`
  color: ${({ theme }) => theme.color.titleTertiary};
  opacity: 1;
  font-size: 0.9rem;
  font-weight: 500;
  font-family: ${({ theme }) => theme.font.main},
    ${({ theme }) => theme.font.generic};
  color: ${({ theme }) => theme.color.textPrimary};
  line-height: 1.35;
`
