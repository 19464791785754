import { createTheme } from '@material-ui/core/styles'
import { PaletteOptions } from '@material-ui/core/styles/createPalette'
import { textSecondary } from '~/sharedStyles/colorPalette'
import { makeStyles } from '@material-ui/core'

export const palette: PaletteOptions = {
  text: {
    primary: '#000000',
    secondary: textSecondary,
  },
  primary: {
    main: '#063E54',
    dark: '#053345',
    light: '#386576',
  },
  secondary: {
    main: '#C3C430',
    dark: '#9C9D26',
    light: '#CFD059',
  },
  success: {
    main: '#C3C430',
    dark: '#9C9D26',
    light: '#CFD059',
  },
  error: {
    main: '#f44336',
    dark: '#d32f2f',
    light: '#e57373',
  },
}

export const theme = createTheme({
  palette: palette,
  typography: {
    fontFamily: 'Montserrat, sans-serif',
    h1: {
      fontSize: 96,
      fontWeight: 'lighter',
      letterSpacing: '-1.5px',
    },
    h2: {
      fontSize: 60,
      fontWeight: 'normal',
      letterSpacing: '-0.5px',
    },
    h3: {
      fontSize: 48,
      fontWeight: 'normal',
      letterSpacing: '0px',
    },
    h4: {
      fontSize: 34,
      fontWeight: 'normal',
      letterSpacing: '0.25px',
    },
    h5: {
      fontSize: 24,
      fontWeight: 'normal',
      letterSpacing: '0px',
    },
    h6: {
      fontSize: 20,
      fontWeight: 'bold',
      letterSpacing: '0.15px',
    },
    subtitle1: {
      fontSize: 16,
      fontWeight: 'lighter',
      letterSpacing: '0.15px',
    },
    subtitle2: {
      fontSize: 18,
      fontWeight: 'bold',
      letterSpacing: '0.15px',
    },
    body1: {
      fontSize: 16,
      fontWeight: 'lighter',
      letterSpacing: '0.15px',
    },
    body2: {
      fontSize: 14,
      fontWeight: 'lighter',
      letterSpacing: '0.15px',
    },
  },
  overrides: {
    MuiButton: {
      textSecondary: {
        color: '#4e4e13',
      },
    },
    MuiTypography: {
      gutterBottom: {
        paddingBottom: '0.75em',
        marginBottom: 0,
      },
    },
  },
})

export const useMuiStyles = makeStyles(() => ({
  bold: {
    fontWeight: 700,
  },
  normal: {
    fontWeight: 400,
  },
  capitalize: {
    textTransform: 'capitalize',
  },
}))

export const isDarkColor = (hexColor: string): boolean => {
  // Remove the '#' character if present
  hexColor = hexColor.replace('#', '')

  // Convert hex to RGB
  const r = parseInt(hexColor.substring(0, 2), 16)
  const g = parseInt(hexColor.substring(2, 4), 16)
  const b = parseInt(hexColor.substring(4, 6), 16)

  // Calculate the luminance of the color
  const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b

  // Determine if the background is dark or light
  // Using a threshold of 128 (midpoint of 0-255)
  return luminance < 128
}
